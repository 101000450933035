import { useStaticQuery, graphql } from 'gatsby';

/**
 * Returns secrets that are added to siteMetadata in gatsby-config.
 *
 * @returns {Object} An object containing site secrets.
 */
export const useElasticValues = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteSecrets {
        site {
          siteMetadata {
            searchKey
            searchIndex
          }
        }
      }
    `
  );
  const { searchIndex } = site.siteMetadata;
  const url = new URL(searchIndex);
  const host = url.origin;
  const index = url.pathname.replace('/', '');

  return { ...site.siteMetadata, index, host };
};
