import React, { useContext } from 'react';
import { CompareStateContext } from '../compare-state-provider';
import styles from './compare-state-toggle.module.scss';

const CompareStateToggle = ({ articleId, name }) => {
  const {
    compareState,
    addCompareArticle,
    removeCompareArticle,
    mayAddArticle
  } = useContext(CompareStateContext);

  const handleChange = (e) => {
    if (e.target.checked) {
      addCompareArticle(articleId);
    } else {
      removeCompareArticle(articleId);
    }
  };

  const value = compareState[articleId] ? true : false;

  return (
    <div className={styles.compareContainer}>
      <input
        className={styles.compareButton}
        id={articleId}
        type="checkbox"
        checked={value}
        value={value}
        disabled={!mayAddArticle() && !value}
        onChange={handleChange}
      />
      <label
        className={styles.compareLabel}
        htmlFor={articleId}
        aria-label={`Include the article ${name} for comparison.`}
      >
        Compare
      </label>
    </div>
  );
};

export default CompareStateToggle;
