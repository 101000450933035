import React, { useEffect, useState } from 'react';
import styles from './search-filters-panel.module.scss';
import Button from '../button';

import ITypeFacetList from '../itype-facet-list';
import {
  FacetCheckbox,
  CountriesCheckbox,
  InstitutionsCheckbox,
  IssuesCheckbox,
  CrisesCheckbox,
  KeyProgramTypesCheckbox,
  RefinementList
} from '../facet-checkbox';

import {
  connectCurrentRefinements,
  connectRefinementList
} from 'react-instantsearch-dom';
import RefinementListContentType from '../refinement-list-type/refinement-list-type';
import { useCountries } from '../../global/hooks/useCountries';
import { useCrises } from '../../global/hooks/useCrises';
import { useInstitutions } from '../../global/hooks/useInstitutions';

const ClearRefinements = ({ items, refine }) => (
  <button
    onClick={() => refine(items)}
    className={styles.resetButton}
    disabled={!items.length}
  >
    Clear all filters
  </button>
);

const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);

const CustomRefinementList = ({ items, refine, currentRefinement }) => {
  return (
    <ITypeFacetList
      items={items}
      currentRefinement={currentRefinement}
      refine={refine}
      itemComponent={FacetCheckbox}
    />
  );
};

const RefinementListIntervention = connectRefinementList(CustomRefinementList);

const SearchFiltersPanel = () => {
  const [showCountries, setCountries] = useState(false);
  const [showInstitutions, setInstitutions] = useState(false);
  const [showCrises, setCrises] = useState(false);
  const [showIssues, setIssues] = useState(false);
  const [showDocs, setDocs] = useState(false);
  const [hideFilters, setHideFilters] = useState(true);

  function toggleFilters() {
    setHideFilters(!hideFilters);
  }
  if (typeof window !== 'undefined') {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 768) {
        setHideFilters(false);
      } else {
        setHideFilters(true);
      }
    });
  }

  useEffect(() => {
    if (window.innerWidth > 768) {
      setHideFilters(false);
    }
  }, []);

  return (
    <>
      <div
        className={`${styles.filters} ${
          hideFilters ? styles.filtersHidden : ''
        }`}
      >
        <CustomClearRefinements />

        <h2 className={styles.contentTypeHeader}>Content Types</h2>
        <RefinementListContentType
          id="content-type"
          title="Article"
          operator="or"
          attribute="type"
        />
        {/* Intervention types */}
        <h2 className={styles.interventionsHeader}>Intervention Types</h2>
        <RefinementListIntervention
          id="intervention"
          title="Intervention"
          attribute="field_intervention_type"
          operator="or"
        />
        {/* Document Types */}
        <h3 className={styles.filterHeader}>
          <Button
            color="transparent"
            aria-expanded={!showDocs ? 'false' : 'true'}
            aria-controls={'resource-type-content'}
            onClick={() => setDocs(!showDocs)}
            className={styles.filterButton}
          >
            Document types
          </Button>
        </h3>
        <div
          className={showDocs ? '' : styles.hidden}
          id={'resource-type-content'}
          aria-hidden={!showDocs ? 'true' : 'false'}
        >
          <RefinementList
            id="resource-type"
            title="Resource Type"
            attribute="field_resource_type"
            operator="or"
            itemComponent={KeyProgramTypesCheckbox}
          />
        </div>
        {/* Countries */}
        <h3 className={styles.filterHeader}>
          <Button
            color="transparent"
            onClick={() => setCountries(!showCountries)}
            className={styles.filterButton}
            aria-expanded={!showCountries ? 'false' : 'true'}
            aria-controls={'countries-content'}
          >
            Countries
          </Button>
        </h3>
        <div
          className={showCountries ? '' : styles.hidden}
          id={'countries-content'}
          aria-hidden={!showCountries ? 'true' : 'false'}
        >
          <RefinementList
            id="countries"
            title="Countries"
            attribute="field_countries_and_regions"
            operator="or"
            itemComponent={CountriesCheckbox}
            isAlphabetical={true}
            alphabeticalArray={useCountries()}
            showMore={true}
            showMoreLimit={useCountries().length}
            limit={10}
          />
        </div>
        {/* Institutions */}
        <h3 className={styles.filterHeader}>
          <Button
            aria-expanded={!showInstitutions ? 'false' : 'true'}
            aria-controls={'institutions-content'}
            color="transparent"
            onClick={() => setInstitutions(!showInstitutions)}
            className={styles.filterButton}
          >
            Institutions
          </Button>
        </h3>
        <div
          className={showInstitutions ? '' : styles.hidden}
          id={'institutions-content'}
          aria-hidden={!showInstitutions ? 'true' : 'false'}
        >
          <RefinementList
            id="institutions"
            title="Institutions"
            attribute="field_institutions"
            operator="or"
            itemComponent={InstitutionsCheckbox}
            isAlphabetical={true}
            alphabeticalArray={useInstitutions()}
            showMore={true}
            showMoreLimit={useInstitutions().length}
            limit={10}
          />
        </div>
        <h3 className={styles.filterHeader}>
          <Button
            aria-expanded={!showCrises ? 'false' : 'true'}
            aira-controls={'crises-content'}
            color="transparent"
            onClick={() => setCrises(!showCrises)}
            className={styles.filterButton}
          >
            Crises
          </Button>
        </h3>
        <div
          className={showCrises ? '' : styles.hidden}
          id={'crises-content'}
          aria-hidden={!showCrises ? 'true' : 'false'}
        >
          <RefinementList
            id="crises"
            title="Crises"
            attribute="field_crises"
            operator="or"
            itemComponent={CrisesCheckbox}
            isAlphabetical={true}
            alphabeticalArray={useCrises()}
            showMore={true}
            showMoreLimit={useCrises().length}
            limit={10}
          />
        </div>
        <h3 className={styles.filterHeader}>
          <Button
            aria-expanded={!showIssues ? 'false' : 'true'}
            aria-controls={'issues-content'}
            color="transparent"
            onClick={() => setIssues(!showIssues)}
            className={styles.filterButton}
          >
            Issues
          </Button>
        </h3>
        <div
          className={showIssues ? '' : styles.hidden}
          id={'issues-content'}
          aria-hidden={!showIssues ? 'true' : 'false'}
        >
          <RefinementList
            id="issues"
            title="Issues"
            attribute="field_issues"
            operator="or"
            itemComponent={IssuesCheckbox}
          />
        </div>
      </div>
      <Button
        onClick={toggleFilters}
        color="blue"
        textStyle="bold"
        className={styles.togglefiltersButton}
      >
        {hideFilters ? 'Show Filters' : 'Hide Filters'}
      </Button>
    </>
  );
};

export default SearchFiltersPanel;
