import { useStaticQuery, graphql } from 'gatsby';

/**
 * Returns content type information.
 *
 * @returns {Array} An array containing taxonomy terms for content type names and drupal internal types.
 */

export const useContentTypes = () => {
  const { allNodeTypeNodeType } = useStaticQuery(
    graphql`
      query ContentTypes {
        allNodeTypeNodeType(
          filter: {
            drupal_internal__type: {
              in: ["survey", "case_studies", "overviews"]
            }
          }
        ) {
          nodes {
            name
            drupal_internal__type
            drupal_id
          }
        }
      }
    `
  );

  // Clean up the field mapping so it's easier to reference them
  // in the component.
  const contentTypes = allNodeTypeNodeType.nodes.map((type) => {
    return {
      label: type.name,
      id: type.drupal_id,
      internalType: type.drupal_internal__type
    };
  });

  return contentTypes;
};
