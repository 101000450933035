import React, { useState } from 'react';

const SearchKitContext = React.createContext();

/**
 * We use the old class extends pattern because we need certain methods
 * from SearchkitComponent in order to get data about filters.
 */
const SearchKitContextProvider = ({ children }) => {
  // The purpose of this class is to provide values derived from
  // SearchKitComponent methods to child components via context API.
  const [selectedResourceTypes, setSelectedResourceTypesState] = useState([]);

  const setSelectedResourceTypes = (selectedItems) => {
    setSelectedResourceTypesState(() => {
      let newState = [];
      selectedItems.map(({ id, currentRefinement }) => {
        if (id === 'field_resource_type') newState.push(...currentRefinement);
      });
      return newState;
    });
  };

  return (
    <SearchKitContext.Provider
      value={{
        selectedResourceTypes,
        setSelectedResourceTypes
      }}
    >
      {children}
    </SearchKitContext.Provider>
  );
};

export { SearchKitContextProvider, SearchKitContext };
