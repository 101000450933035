import * as React from 'react';
import { useInterventions } from '../../global/hooks/useInterventions';
import { block } from 'searchkit';
import Button from '../button';
import styles from './itype-facet-list.module.scss';

const ITypeFacetList = ({
  mod = 'sk-item-list',
  itemComponent,
  items = [],
  toggleItem,
  setItems,
  disabled,
  className,
  docCount,
  currentRefinement,
  refine
}) => {
  const bemBlocks = {
    container: block(mod).el,
    option: block(`${mod}-option`).el
  };

  /**
   *
   * @param {*} option
   */
  const isActive = (option) => {
    return currentRefinement
      ? currentRefinement
          .map((id) => id.toString())
          .includes(option.label.toString())
      : [];
  };

  const interventionTypes = useInterventions();

  /**
   * Gets an item by its term ID.
   *
   * @param {*} termId
   *   The term ID of an item.
   */
  const getItemByTermId = (termId) =>
    items.find((item) => item.label.toString() === termId.toString());

  /**
   * Sub-component to render a single i-type checkbox.
   *
   * @param {*} checkbox
   *   Data about a single checkbox. See useInterventions().
   */
  const ITypeFacetCheckbox = ({ checkbox }) => {
    if (!items) {
      return null;
    }

    const option = getItemByTermId(checkbox.taxonomyId);

    if (!option) {
      return null;
    }

    const key = option.label.toString();

    return React.createElement(itemComponent, {
      ...option,
      label: checkbox.label,
      onClick: () => refine(option.value),
      key,
      itemKey: option.label,
      count: option.count,
      bemBlocks,
      rawCount: option.count,
      listDocCount: docCount,
      disabled: option.disabled
    });
  };

  /**
   * Toggle selected state of all checkboxes in an i-type group.
   *
   * See useInterventions().
   *
   * @param {*} iTypeGroup
   *   An intervention type group.
   */
  const toggleAllInGroup = (iTypeGroup) => {
    const taxonomyIds = iTypeGroup.checkboxes.map((checkbox) =>
      checkbox.taxonomyId.toString()
    );

    // If all of this group's checkbox IDs are already selected, deselect
    // the whole group.
    let set = [];
    if (
      currentRefinement.filter((id) => taxonomyIds.includes(id)).length ===
      taxonomyIds.length
    ) {
      // Difference of selected items and taxonomy ids.
      set = currentRefinement.filter((id) => !taxonomyIds.includes(id));
    } else {
      // Unique union of selected items and taxonomy ids.
      set = [...new Set([...currentRefinement, ...taxonomyIds])];
    }
    refine(set);
  };

  return (
    <div
      data-qa="options"
      className={bemBlocks.container().mix(className).state({ disabled })}
    >
      {interventionTypes.map(
        (iTypeGroup) =>
          iTypeGroup.checkboxes && (
            <div className={styles.itypeContainer} key={iTypeGroup.label}>
              <h3 className={styles.itypeHeading}>
                <Button
                  className={styles.itypeButton}
                  color="transparent"
                  onClick={() => toggleAllInGroup(iTypeGroup)}
                >
                  {iTypeGroup.label}
                </Button>
              </h3>
              <div className={styles.itypeContent}>
                {iTypeGroup.checkboxes.map((checkbox) => (
                  <ITypeFacetCheckbox
                    checkbox={checkbox}
                    key={checkbox.taxonomyId}
                  />
                ))}
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default ITypeFacetList;
