import { useStaticQuery, graphql } from 'gatsby';
import { groupBy } from 'lodash';

/**
 * Returns Interventions taxonomy term information.
 *
 * @returns {Array} An array containing taxonomy terms for intervention names and drupal ids.
 */

export const useInterventions = () => {
  const { allTaxonomyTermInterventions } = useStaticQuery(
    graphql`
      query interventionsAndAllFiltersAndInterventions {
        allTaxonomyTermInterventions {
          nodes {
            name
            drupal_id
            relationships {
              parent {
                name
              }
              node__case_studies {
                drupal_id
              }
              node__overviews {
                drupal_id
              }
              node__survey {
                drupal_id
              }
            }
            drupal_internal__tid
          }
        }
      }
    `
  );

  // First we want to make sure we are only grabbing intervention
  // taxonomy terms that are currently being tagged on surveys, overviews and/or case studies.
  const validFilters = allTaxonomyTermInterventions.nodes.filter(
    (intervention) =>
      intervention?.relationships?.node__survey !== null ||
      intervention?.relationships?.node__overviews !== null ||
      intervention?.relationships?.node__case_studies !== null
  );
  // Then we create an object of arrays grouped by the parent name (i.e. Lending, Guaranteeing, Buying, etc.)
  // If there is no parent name, it is because they are using only a parent tag and it will appear in the
  // name field, so we will group by that instead.
  const groupedFilters = groupBy(validFilters, (item) =>
    item.relationships?.parent[0]?.name
      ? item.relationships?.parent[0]?.name
      : item?.name
  );
  // Then convert into an array of objects with a label of the parent name
  // and the filters associated with each encapsulated in a checkboxes array.
  const arrayOfFilters = Object.keys(groupedFilters).map((key) => {
    return {
      label: key,
      checkboxes: groupedFilters[key],
      allFilters: validFilters
    };
  });

  // Then we want to give each intervention checkbox an object of label, id, parent and taxonomyIds.
  const interventions = arrayOfFilters.map((filter) => {
    filter.checkboxes = filter.checkboxes.map((checkbox) => {
      return {
        label: checkbox?.name,
        id: checkbox?.drupal_id,
        parent: checkbox?.relationships?.parent[0]?.name,
        taxonomyId: checkbox?.drupal_internal__tid
      };
    });
    return filter;
  });

  return interventions;
};
