import { useStaticQuery, graphql } from 'gatsby';

/**
 * Returns Country taxonomy term information.
 *
 * @returns {Array} An array containing taxonomy terms for country names and drupal ids.
 */
export const useCountries = () => {
  const { allTaxonomyTermCountriesAndRegions } = useStaticQuery(
    graphql`
      query CountriesAndRegions {
        allTaxonomyTermCountriesAndRegions {
          nodes {
            name
            drupal_id
            drupal_internal__tid
            relationships {
              node__survey {
                drupal_id
              }
              node__overviews {
                drupal_id
              }
              node__case_studies {
                drupal_id
              }
            }
          }
        }
      }
    `
  );

  // Clean up the field mapping so it's easier to reference them
  // in the component.
  const countries = allTaxonomyTermCountriesAndRegions.nodes
    .filter(
      (country) =>
        country?.relationships?.node__survey !== null ||
        country?.relationships?.node__overviews !== null ||
        country?.relationships?.node__case_studies !== null
    )
    .sort((a, b) => {
      const labelA = a.name.toUpperCase();
      const labelB = b.name.toUpperCase();
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    })
    .map((country) => {
      return {
        label: country?.name,
        id: country?.drupal_id,
        taxonomyId: country?.drupal_internal__tid
      };
    });

  return countries;
};
