import React from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import { ContentTypeCheckbox } from '../facet-checkbox/facet-checkbox';

const RefinementList = ({ items, refine }) =>
  items.map((item) => {
    const onClick = (event) => {
      event.preventDefault();
      refine(item.value);
    };
    return <ContentTypeCheckbox {...item} onClick={onClick} key={item.label} />;
  });

const RefinementListContentType = connectRefinementList(RefinementList);

export default RefinementListContentType;
