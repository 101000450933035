import { useStaticQuery, graphql } from 'gatsby';

/**
 * Returns Institutions taxonomy term information.
 *
 * @returns {Array} An array containing taxonomy terms for institution names and drupal ids.
 */

export const useInstitutions = () => {
  const { allTaxonomyTermInstitutions } = useStaticQuery(
    graphql`
      query Institutions {
        allTaxonomyTermInstitutions {
          nodes {
            name
            drupal_id
            drupal_internal__tid
            relationships {
              node__case_studies {
                drupal_id
              }
              node__overviews {
                drupal_id
              }
              node__survey {
                drupal_id
              }
            }
          }
        }
      }
    `
  );

  // Clean up the field mapping so it's easier to reference them
  // in the component.
  const institutions = allTaxonomyTermInstitutions.nodes
    .filter(
      (institution) =>
        institution?.relationships?.node__survey !== null ||
        institution?.relationships?.node__overviews !== null ||
        institution?.relationships?.node__case_studies !== null
    )
    .sort((a, b) => {
      const labelA = a.name.toUpperCase();
      const labelB = b.name.toUpperCase();
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    })
    .map((institution) => {
      return {
        label: institution?.name,
        id: institution?.drupal_id,
        taxonomyId: institution?.drupal_internal__tid
      };
    });

  return institutions;
};
