import React, { useEffect, useState, useRef, useContext } from 'react';
import Searchkit from 'searchkit-latest';
import {
  InstantSearch,
  SearchBox,
  Hits,
  Pagination,
  Configure,
  connectStateResults,
  connectCurrentRefinements
} from 'react-instantsearch-dom';
import Client from '@searchkit/instantsearch-client';
import styles from './search.module.scss';
import PageLayout from '../components/page-layout';
import {
  SearchPageWrapper,
  ContentContainer,
  ContentSidebar
} from '../components/content-layout';
import ComparePanel from '../components/compare-panel';
import CompareStateProvider from '../components/compare-state-provider';
import { SearchKitContextProvider } from '../components/searchkit-context-provider';
import SEO from '../components/seo';
import { useElasticValues } from '../global/hooks/useElasticValues';
import SearchResult from '../components/search-result/search-result';
import SearchFiltersPanel from '../components/search-filters-panel/search-filters-panel';
import qs from 'qs';
import { useNavigate } from '@reach/router';
import { SearchKitContext } from '../components/searchkit-context-provider/searchkit-context-provider';

const DEBOUNCE_TIME = 400;
const HITS_PER_PAGE = 10;
const createURL = (state) => `?${qs.stringify(state)}`;
const searchStateToUrl = (searchState) =>
  searchState ? createURL(searchState) : '';
const urlToSearchState = ({ search }) => qs.parse(search.slice(1));

// This updates the selected resources types array
// in Searchkit Context
const CurrentRefinement = ({ items }) => {
  const { setSelectedResourceTypes } = useContext(SearchKitContext);
  useEffect(() => {
    setSelectedResourceTypes(items);
  }, [items]);
  return null;
};

const SearchResultCurrentRefinements = connectCurrentRefinements(
  CurrentRefinement
);

const StateResults = ({ searchResults, searching, searchState }) => {
  const hasResults = searchResults && searchResults.nbHits !== 0;
  const showPagination = hasResults && searchResults.nbHits > 10;
  const { query } = searchState;

  // Adding to move user back to top of results,
  // if they choose a filter and results change.
  useEffect(() => {
    let element = document.querySelector(
      '.page-layout-module--searchPageWrapper--2Sv_D'
    );
    element.scrollIntoView({ behavior: 'smooth' });
  }, [searchResults]);

  return (
    <>
      <div className="sk-no-hits" hidden={hasResults || searching}>
        <div className="sk-no-hits__info">
          No results found{query ? ` for ${query}` : ''}.
          <noscript>
            <br />
            <br />
            In order to view search results, please ensure that you have enabled
            JavaScript. If you have continued difficulty accessing the
            materials, please contact{' '}
            <a href="mailto:christian.mcnamara@yale.edu">
              christian.mcnamara@yale.edu
            </a>
            .
          </noscript>
        </div>
        <div className="sk-no-hits__steps"></div>
      </div>

      <div hidden={!showPagination || searching}>
        <Pagination
          hidden={showPagination}
          translations={{
            previous: (
              <button
                className={styles.paginationButton}
                aria-label="Go to the previous page of search results."
              >
                Previous
              </button>
            ),
            next: (
              <button
                className={styles.paginationButton}
                aria-label="Go to the next page of search results."
              >
                Next
              </button>
            ),
            first: null
          }}
        />
      </div>
    </>
  );
};

const SearchResults = connectStateResults(StateResults);

const SearchPage = ({ location }) => {
  const { searchKey, index, host } = useElasticValues();
  const navigate = useNavigate();
  const sk = new Searchkit({
    connection: {
      host,
      apiKey: searchKey
    },
    search_settings: {
      highlight_attributes: ['title'],
      search_attributes: ['title', 'field_intervention_type', 'type'],
      result_attributes: [
        'title',
        'key_program_documents',
        'type',
        'uuid',
        'field_intervention_type',
        'key_terms',
        'country_region_name',
        'url'
      ],
      facet_attributes: [
        'type',
        'field_intervention_type',
        'field_resource_type',
        'field_countries_and_regions',
        'field_institutions',
        'field_crises',
        'field_issues',
        'field_is_sample'
      ]
    }
  });

  const searchClient = Client(sk);

  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const debouncedSetStateRef = useRef(null);
  const inputRef = useRef(null);

  function onSearchStateChange(updatedSearchState) {
    clearTimeout(debouncedSetStateRef.current);

    debouncedSetStateRef.current = setTimeout(() => {
      navigate(searchStateToUrl(updatedSearchState));
    }, DEBOUNCE_TIME);

    setSearchState(updatedSearchState);
  }

  useEffect(() => {
    setSearchState(urlToSearchState(location));
  }, [location]);

  useEffect(() => {
    if (inputRef.current) {
      const searchContainer = inputRef.current;

      // element that will be wrapped
      const searchInput = searchContainer.querySelector('input');

      // create label container
      const label = document.createElement('label');
      label.classList.add(styles.searchLabel);
      label.insertAdjacentHTML('afterbegin', '<span>Search Input</span>');

      // insert label before search input in the DOM tree
      searchInput.parentNode.insertBefore(label, searchInput);

      // move search input into label
      label.appendChild(searchInput);
    }
  }, []);

  return (
    <PageLayout path={`search`} type="search">
      <SEO title="Search" />
      <InstantSearch
        searchClient={searchClient}
        indexName={index}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
      >
        <Configure
          filters='NOT field_is_sample:"1"'
          hitsPerPage={HITS_PER_PAGE}
        ></Configure>
        <SearchKitContextProvider>
          <CompareStateProvider>
            <SearchPageWrapper>
              <ContentSidebar type="search">
                <SearchFiltersPanel />
              </ContentSidebar>
              <ContentContainer layout={'left'}>
                <div className={styles.searchContent} role="search">
                  <div className={styles.searchTextBox}>
                    <h1 className={styles.searchHeader}>Search</h1>
                    <div ref={inputRef}>
                      <SearchBox
                        autofocus={true}
                        searchOnChange={true}
                        queryFields={['rendered_item', 'title']}
                        prefixQueryFields={['rendered_item', 'title']}
                        prefixQueryOptions={{ analyzer: 'stop' }}
                        translations={{ placeholder: 'Search' }}
                      />
                    </div>
                  </div>
                </div>
                <SearchResultCurrentRefinements />
                <Hits hitComponent={SearchResult} />
                <SearchResults />
                <ComparePanel />
              </ContentContainer>
            </SearchPageWrapper>
          </CompareStateProvider>
        </SearchKitContextProvider>
      </InstantSearch>
    </PageLayout>
  );
};

export default SearchPage;
