import React, { useContext } from 'react';
import { CompareStateContext } from '../compare-state-provider';
import styles from './compare-panel.module.scss';
import Button from '../button';

const ComparePanel = () => {
  const { clearCompareArticles, buildCompareUrl, getCompareCount } = useContext(
    CompareStateContext
  );

  return (
    <>
      {getCompareCount() > 0 && (
        <div className={styles.comparePanel}>
          <div className={styles.compareDisclaimer}>
            Select up to 6 articles
          </div>
          <div>
            You've selected {getCompareCount()} article
            {getCompareCount() > 1 && 's'}
          </div>
          <div className={styles.compareButtons}>
            {getCompareCount() > 1 ? (
              <Button
                className={styles.compareButton}
                color="blue"
                to={buildCompareUrl()}
              >
                Compare
              </Button>
            ) : (
              <Button
                className={styles.compareButtonDisabled}
                disabled={true}
                title={'Two or more selections are required.'}
              >
                Compare
              </Button>
            )}
            <Button
              className={styles.comparePanelCancel}
              color="white"
              onClick={clearCompareArticles}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ComparePanel;
