import { useStaticQuery, graphql } from 'gatsby';

/**
 * Returns Country taxonomy term information.
 *
 * @returns {Array} An array containing taxonomy terms for country names and drupal ids.
 */
export const useKeyProgramTypes = () => {
  const { allTaxonomyTermKeyProgramType } = useStaticQuery(
    graphql`
      query KeyProgramType {
        allTaxonomyTermKeyProgramType {
          nodes {
            name
            drupal_id
            drupal_internal__tid
          }
        }
      }
    `
  );

  // Clean up the field mapping so it's easier to reference them
  // in the component.
  const keyProgramTypes = allTaxonomyTermKeyProgramType.nodes.map((term) => {
    return {
      label: term?.name,
      id: term?.drupal_id,
      taxonomyId: term?.drupal_internal__tid
    };
  });

  return keyProgramTypes;
};
