import React, { useState } from 'react';
import styles from './facet-checkbox.module.scss';
import { useIssues } from '../../global/hooks/useIssues';
import { useContentTypes } from '../../global/hooks/useContentTypes';
import { useKeyProgramTypes } from '../../global/hooks/useKeyProgramTypes';
import PropTypes from 'prop-types';
import Button from '../button';
import { connectRefinementList } from 'react-instantsearch-dom';

/**
 * General facet single checkbox component.
 */
const FacetCheckbox = ({ count, onClick, label, isRefined }) => {
  return (
    <Button
      className={`sk-item-list-option sk-item-list__item ${
        isRefined ? 'is-selected' : ''
      }`}
      onClick={onClick}
    >
      <span className={styles.facetContent}>
        <span className={styles.facetLabel}>{label}</span>
        <span className={styles.facetCount}>{count}</span>
      </span>
    </Button>
  );
};

FacetCheckbox.propTypes = {
  count: PropTypes.number,
  bemBlocks: PropTypes.object,
  onClick: PropTypes.func,
  label: PropTypes.string
};

export { FacetCheckbox };

/**
 * General component for rendering a flat set of taxonomy terms.
 *
 * @param {*} props
 *   Passed to children.
 */
export const FlatTermCheckbox = (props) => {
  const term = props.terms.find(
    (term) => term.taxonomyId.toString() === props.label.toString()
  );

  if (!term) {
    return null;
  }

  return <FacetCheckbox {...props} label={term.label} />;
};

/**
 * ===== Specific facet components =====
 */

/**
 * Component for rendering content type facets.
 *
 * @param {*} props
 *   Passed through to children.
 */
export const ContentTypeCheckbox = (props) => {
  const contentTypes = useContentTypes();
  const contentType = contentTypes.find(
    (contentType) =>
      contentType.internalType.toString() === props.label.toString()
  );

  return <FacetCheckbox {...props} label={contentType.label} />;
};

/**
 * Render facets for countries.
 *
 * @param {*} props
 *   Passed through to children.
 */
export const CountriesCheckbox = (props) => {
  return <FacetCheckbox {...props} label={props.name} />;
};

/**
 * Render facets for Crises.
 *
 * @param {*} props
 *   Passed through to children.
 */
export const CrisesCheckbox = (props) => {
  return <FacetCheckbox {...props} label={props.name} />;
};

/**
 * Render facets for Institutions.
 *
 * @param {*} props
 *   Passed through to children.
 */
export const InstitutionsCheckbox = (props) => {
  return <FacetCheckbox {...props} label={props.name} />;
};

/**
 * Render facets for Issues.
 *
 * @param {*} props
 *   Passed through to children.
 */
export const IssuesCheckbox = (props) => {
  return <FlatTermCheckbox {...props} terms={useIssues()} />;
};

/**
 * Render facets for key program types.
 *
 * @param {*} props
 *   Passed through to children.
 */
export const KeyProgramTypesCheckbox = (props) => {
  return <FlatTermCheckbox {...props} terms={useKeyProgramTypes()} />;
};

const CustomRefinementList = ({ items, refine, itemComponent, ...props }) => {
  const [extended, setExtended] = useState(false);
  const limit = extended ? props.showMoreLimit : props.limit;
  let itemsArray;

  if (props.isAlphabetical) {
    let mergedArrayList = [];

    for (let alphabetical in props.alphabeticalArray) {
      let obj = {
        id: props.alphabeticalArray[alphabetical].id,
        name: props.alphabeticalArray[alphabetical].label,
        taxonomyId: props.alphabeticalArray[alphabetical].taxonomyId
      };

      for (let item in items) {
        if (
          props.alphabeticalArray[alphabetical].taxonomyId.toString() ===
          items[item].label
        ) {
          obj.count = items[item].count;
          obj.isRefined = items[item].isRefined;
          obj.label = items[item].label;
          obj.value = items[item].value;
        }
      }

      mergedArrayList.push(obj);
    }

    itemsArray = mergedArrayList.filter((obj) =>
      Object.keys(obj).includes('count')
    );
  } else {
    itemsArray = items;
  }

  return (
    <>
      {itemsArray.slice(0, limit).map((item) =>
        React.createElement(itemComponent, {
          ...item,
          key: item.label,
          onClick: () => refine(item.value)
        })
      )}
      {props.showMore && itemsArray.length > props.limit && (
        <Button
          color="blue"
          size={'small'}
          className={styles.showMoreOrLessToggle}
          onClick={() => setExtended(!extended)}
        >
          {extended ? 'Show Less' : 'Show More'}
        </Button>
      )}
    </>
  );
};

export const RefinementList = connectRefinementList(CustomRefinementList);
